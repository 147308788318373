interface MetaTagsProps {
  title?: string;
  description?: string;
  keywords?: string[];
}

interface MetaGenerationOptions {
  appendTitle?: boolean;
}

const defaultOptions = {
  appendTitle: true,
};

export function generateMetaTags(
  {title: titleProp, description, keywords = []}: MetaTagsProps,
  options: MetaGenerationOptions = {}
) {
  const opts = {...defaultOptions, ...options};
  const title = opts.appendTitle && titleProp ? `${titleProp} | Smart Delegation App` : titleProp;

  return {
    ...(title && {
      title: title,
      'og:title': title,
      'twitter:title': title,
    }),
    ...(description && {
      description: description,
      'og:description': description,
      'twitter:description': description,
    }),
    ...(keywords.length > 0 && {
      keywords: keywords.join(', '),
    }),
  };
}
